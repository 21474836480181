import React, { useEffect, useState } from "react";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBuilding, faSearch, faSignOutAlt, faTimes, faTrash, faUser, faUserCheck, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { PositionsStore } from "../../../positions/stores/PositionsStore";
import util from "../../../utils/miniUtils";
import ImageComponent from "../../../utils/ImageComponent";

import { Dropdown } from "react-bootstrap";
import Register from "../../../positions/modals/Register";
import Login from "../../../positions/modals/Login";
import TalentNotification from "../../../positions/navbar/TalentNotification";
import FillProfile from "../../../positions/modals/FillProfileNew";
import ProfilePopup from "../../../shared/profile_component/ProfilePopup";
import { useLocation } from "react-router-dom";
import ImageWithFallback from "../../../shared/ImageWithFallback";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { Link } from "react-router-dom"

const Header = ( { handleShowModal } ) => {
    const [navList, setNavList] = useState( false );
    const position = PositionsStore.useState( state => state );
    const [show, setShow] = useState( false );
    const [showModal, setShowModal] = useState( false );
    const location = useLocation();
    const handleClose = () => setShowModal( false );
    const handleShow = () => setShowModal( true );
    const [appInfo, setAppInfo] = useState( { title: "", logo: "", small_logo: "" } );
    const [selectedLang, setSelectedLang] = useState( "en" );

    const handleLanguageChange = ( lang ) => {
        setSelectedLang( lang );
        changeLanguage( lang );
    };
    // Handle display reCaptcha
    useEffect( () => {
        setTimeout( () => {
            setShow( false );
        }, 2500 );
    }, [show] );
    const history = useHistory();
    useEffect( () => {

    }, [position.isLogged] );

    useEffect( () => {
        getAppInfo();
    }, [] );

    const { i18n } = useTranslation();

    const changeLanguage = ( lang ) => {
        // i18n.changeLanguage( event.target.value );
        i18n.changeLanguage( lang );
   
    };
   
    const { t } = useTranslation();
    // Handle changes in search bar or advanced search

    const deleteAccount = async () => {
        try {
            const request = await util.axios.get( `/delete_data` );
            const { error, msg } = request.data;
            util.logOut();
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const go_mainPage = () => {
        const pageUrl = '/';
        window.history.pushState( '', '', pageUrl );
        window.location.reload();
    };

    const getAppInfo = async () => {
        try {
            const request = await util.axios.get( '/app_resources/settings/app_info' );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
            setAppInfo( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    { util.LOADING_SCREEN( position.loading ); }

    return (
        <>
            <header>
                <div className='ej_container flex'>
                    <div className='logo isCursor' onClick={go_mainPage}>
                        {appInfo.logo ? <ImageWithFallback src={`${util.baseUrl()}${util.AWSURL()}/global/images/${appInfo.logo}`} alt={appInfo.title || "eJRekruit"} fallbackSrc='./images/demo_logo.svg' /> :
                            <img src='./images/demo_logo.svg' alt='ejRekruit' />}
                    </div>

                    <div className='nav'>
                        <ul>
                            {position.isLogged ? (
                                <div className="align-items-center d-flex">
                                    {util.user?.name ? <TalentNotification /> : ""}
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ minWidth: "12rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            {util.customDropDownName( util.user?.name )}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/appliedJobs" >
                                                <FontAwesomeIcon color="#aeaeae" icon={faBuilding} className="mr-1" />
											Applied Jobs
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={()=>{
                                                window.history.pushState( '', '', "/" );
                                                PositionsStore.update( s=>{
                                                    s.sectionSelected = "Main";
                                                    s.firstTime = true;
                                                } );
                                            }}>
                                                <FontAwesomeIcon color="#aeaeae" icon={faSearch} className="mr-1" />
											Search Jobs
                                            </Dropdown.Item>
                                            {(  util.user && util.user.permissions && util.user.permissions.talent && !util.user.permissions.employee ) && <Dropdown.Item onClick={()=>{
                                                // window.history.pushState('', '', "/");
                                                PositionsStore.update( s=>{
                                                    s.sectionSelected = "Profile";
                                                    s.firstTime = false;
                                                } );
                                            }}>
                                                <FontAwesomeIcon color="#aeaeae" icon={faUserCircle} className="mr-1" />
											Profile
                                            </Dropdown.Item>}
                                            <util.AvailableRole role="Recruiter" selectedRole="recruiter" />
                                            {util.user.role === 2 ? null : <util.AvailableRole role="Talent" selectedRole="talent" />}
                                            <util.AvailableRole role="Client" selectedRole="client" />
                                            <util.AvailableRole role="Partner" selectedRole="partner" />
                                            <util.AvailableRole role="Evaluation" selectedRole="evaluator" />
                                            <util.AvailableRole role="Employee" selectedRole="employee" />
                                            <util.AvailableRole role="Recruitment Manager" selectedRole="recruiter_manager" />
                                            <Dropdown.Item>
                                                <div onClick={()=> deleteAccount()}><FontAwesomeIcon color="#ff7272" icon={faTrash} className="mr-2"/> Delete Account</div>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <div onClick={()=> util.logOut()}><FontAwesomeIcon color="#ff7272" icon={faSignOutAlt} className="mr-2"/> Logout</div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            ) : (
                                <button
                                    onClick={() => PositionsStore.update( s => { s.loginModal = true; } )}
                                    className="btn btn-sm btn-primary"
                                    style={{ width: "120px", height: "30px" }}
                                >
                                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                                    {t( 'Login' )}
                                </button>
                            )}
                        </ul>
                    </div>

                    <div className="language-selector">
                        <span className={selectedLang === "en" ? "active" : ""} onClick={() => handleLanguageChange( "en" )}>EN</span> |
                        <span className={selectedLang === "pl" ? "active" : ""} onClick={() => handleLanguageChange( "pl" )}>PL</span> |
                        <span className={selectedLang === "ar" ? "active" : ""} onClick={() => handleLanguageChange( "ar" )}>AR</span> |
                        {/* <span className={selectedLang === "fr" ? "active" : ""} onClick={() => handleLanguageChange("fr")}>FR</span> | */}
                        <span className={selectedLang === "sv" ? "active" : ""} onClick={() => handleLanguageChange( "sv" )}>SV</span>
                    </div>
                </div>
            </header>

            {position.createAccount ? <Register /> : null}
            {position.loginModal ? <Login /> : null}
            {position.profileProcess ? <FillProfile /> : ""}
            {/* {position.advanceFilter ? <AdvancedSearch makeSearch={()=>search()} />:""} */}
            {showModal && <ProfilePopup show={showModal} handleClose={handleClose} />}
        </>
    );
};

export default Header;